:root {
  --gradient-primary: linear-gradient(to left, #164b60, #16744ebb);
  --gradient-secondary: linear-gradient(to left, #164b6028, #16744e41);
  --color-primary: #164b60;
  --color-secondary: #1b6b93;
  --color-ascent-100: rgb(255, 255, 255, 0.5);
}

/* Body */
body {
  /* text-align: center; */
  background-color: hsl(0, 0%, 97%);
  overflow-x: hidden;
}

h1 {
  font-size: calc(2rem + 1.3vw);
  font-weight: 700;
  margin-bottom: 1.25rem;
}

h2 {
  font-size: calc(1rem + 1.3vw);
  font-weight: 700;
  margin-bottom: 1.25rem;
}

h3 {
  font-size: calc(0.8rem + 0.7vw);
  font-weight: 700;
  margin: 1.25rem 0;
}

p,
ul,
ol {
  font-size: calc(0.6rem + 0.5vw);
  line-height: 1.5;
  margin: 1rem 0 1.875rem 0;
  text-align: justify;
}

/* ------------------------------------------- */

/* Navabar */
.navbar,
.footer {
  background: var(--gradient-primary);
}

/* Landing Section */

@media screen and (max-width: 768px) {
  #landing-overlay div {
    width: 90% !important;
    margin: 0 auto !important;
    left: 0;
    right: 0;
    bottom: 1rem !important;
  }

  .explore-products .clipped-image {
    clip-path: polygon(0 0, 100% 0, 100% 50%, 0 50%) !important;
  }

  .explore-products .explore-products-text {
    width: 90% !important;
  }
}

/* Products Page */
.header {
  position: relative;
  background-image: url("/src/assets/images/products_bg.jpg");
  background-size: cover;
  background-position: center;
  padding: 8rem 0;
}

/* Product Cards */
.product-image-container {
  width: 100%;
  height: 250px;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(22, 75, 96, 0.1);
  background-size: cover;
  background-position: center;
}

.product-image-container::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.custom-modal .modal-dialog {
  width: 90%;
  margin: 1.75rem auto;
}

.custom-modal .modal-content {
  border: none;
  border-radius: 0.5rem;
}

.custom-modal .modal-body {
  padding: 2rem;
}

.custom-modal .modal-footer {
  border-top: none;
  padding: 1rem;
}

/* Utility Classes */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--gradient-secondary);
}

/* Backgrounds */
.bg-primary-color {
  background: var(--gradient-primary) !important;
}

.bg-glassy {
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5.4px);
  box-shadow: 0 0.625rem 1.875rem rgba(0, 0, 0, 0.1);
}

.bg-primary-opacity {
  background: rgba(19, 69, 89, 0.13);
  background: linear-gradient(to left, #164b6045, #16744e45);
  backdrop-filter: blur(5.4px);
  opacity: 0;
}

.bg-primary-opacity:hover {
  opacity: 0.7;
  transition: background-color 0.3s ease-in-out;
}

/* Buttons */

.btn-primary {
  background: var(--gradient-primary) !important;
  box-shadow: 0 0.625rem 1.875rem rgba(0, 0, 0, 0.1);
}

.btn-primary:hover {
  scale: 1.1;
  transition: all 0.3s ease-in-out;
}

.btn-transparent:hover {
  background: linear-gradient(to left, #164b60, #16744ebb) !important;
  transition: background 0.3s ease-in-out;
}

/* Text */

.text-shadow {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}

.text-primary-color {
  background-image: var(--gradient-primary) !important;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  color: var(--color-primary);
}

/* Lists */
ol,
ul {
  /* responsive left margin */
  margin-left: 0.5rem;
}

ul,
.square-bullet-list {
  list-style-type: square !important;
}

.square-bullet-list {
  margin-left: 0;
}

/* Cursor */

.cursor-pointer {
  cursor: pointer;
}

/* Hover Effects */

.scale-hover {
  transition: transform 0.3s ease;
}

.scale-hover:hover {
  transform: scale(1.05);
}
